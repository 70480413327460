<template>
  <div class="search-card-page">
    <div class="search-type">
      <div
        :class="['search-type-item', searchForm.type === 0 ? 'active' : '']"
        @click="searchForm.type = 0"
      >
        运单号查询
      </div>
      <div
        :class="['search-type-item', searchForm.type === 1 ? 'active' : '']"
        @click="searchForm.type = 1"
      >
        转单号查询
      </div>
    </div>
    <a-textarea
      v-model.trim="searchForm.keyWord"
      @keydown.ctrl.enter.native="onSearch"
      placeholder="请输入要查询的单号，多单号以回车键换行或用逗号隔开"
      allow-clear
      :autoSize="{ minRows: 2, maxRows: 4 }"
    />
    <a-button class="submit-btn" type="primary" @click="onSearch"
      >查询</a-button
    >
  </div>
</template>
<script>
export default {
  name: "SearchCard",
  data() {
    return {
      searchForm: {
        type: 0,
        keyWord: "",
      },
    };
  },
  methods: {
    onSearch() {
      this.$emit("onSearch", this.searchForm);
    },
  },
};
</script>
<style lang="less" scoped>
.search-card-page {
  background-color: #fff3f3;
  border-radius: 8px;
  padding: 0 10px 12px 10px;
  text-align: center;
  > .search-type {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    .search-type-item:nth-child(1) {
      margin-right: 24px;
    }
    .search-type-item {
      cursor: pointer;
      color: #333;
      font-size: 14px;
      font-weight: 600;
      padding: 4px 0;
    }
    .active {
      border-bottom: 2px solid #ff4d24;
      color: #ff4d24;
    }
  }
  :deep(.ant-input-affix-wrapper) {
    height: 94px;
  }
  :deep(.ant-input) {
    height: 94px !important;
  }
  .submit-btn {
    margin-top: 12px;
    width: 280px;
    border-radius: 12px;
  }
}
</style>
