<template>
  <div class="tracking-query-page">
    <header class="header-wrapper">
      <img src="../../assets/images/logo.png" alt="logo" />
      <span>安速货运</span>
    </header>
    <a-spin :spinning="searchLoading" tip="玩命加载中...">
      <div class="content-wrapper">
        <div class="track-list">
          <a-collapse v-model="activeKey" expand-icon-position="right">
            <a-collapse-panel
              v-for="(item, index) in tableData"
              :key="`${index}`"
            >
              <template #header>
                <div class="track-item-header">
                  <div class="track-item-header-content">
                    <div class="waybill-no">
                      <span>运单号：{{ item.waybill_no }}</span>
                      <span class="waybill-status">{{ item.status }}</span>
                    </div>
                    <div class="waybill-info">
                      <span>转单号：{{ item.transship_no }}</span>
                      <span>转单快递：{{ item.transship_name }}</span>
                      <span>入仓渠道：{{ item.irikura_name }}</span>
                    </div>
                  </div>
                  <div
                    class="track-last-info"
                    v-if="item.milestones && item.milestones.length"
                  >
                    <span class="timer">{{
                      item.milestones[0].milestone_time
                    }}</span>
                    <span class="info">
                      {{ item.milestones[0].milestone_name }}
                    </span>
                  </div>
                </div>
              </template>
              <a-timeline>
                <a-timeline-item
                  v-for="(row, index) in item.milestones"
                  :key="index"
                  :color="index === 0 ? '#fb4702' : 'gray'"
                >
                  {{ row.milestone_time }}
                  <span>{{ row.milestone_name }}</span>
                </a-timeline-item>
              </a-timeline>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div class="track-search">
          <search-card @onSearch="onSearch"></search-card>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { getTrackingInfo } from "../../api/TrackingQuery/index";
import searchCard from "./searchCard.vue";
export default {
  name: "TrackingQuery",
  components: {
    searchCard,
  },
  data() {
    return {
      activeKey: [],
      tableData: [],
      searchLoading: false,
    };
  },
  methods: {
    onSearch({ type, keyWord }) {
      let params = {};
      let waybillNo = [];
      if (keyWord) {
        const text = keyWord.replace(/\n|，|,/g, ",");
        waybillNo = text.split(",").filter((t) => t);
      } else {
        return this.$message.info("请输入单号!");
      }
      if (type === 0) params.waybillNos = waybillNo;
      else if (type === 1) params.transshipNos = waybillNo;
      this.searchLoading = true;
      getTrackingInfo(params)
        .then((res) => {
          const { code, data, msg } = res.data;
          if (code !== 0) {
            this.$message.info(msg);
            this.tableData = [];
          } else {
            this.tableData = data;
            this.$message.success(msg);
          }
        })
        .finally(() => (this.searchLoading = false))
        .catch((err) => {
          this.$message.error("服务器错误" + err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.tracking-query-page {
  min-height: 100%;
  padding-top: 50px;
  background-color: #eef3fa;
  position: relative;
  .header-wrapper {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    box-shadow: 1px 1px 5px rgba(220, 220, 220, 0.5);
    display: flex;
    align-items: center;
    background-color: #fff;
    > img {
      height: 100%;
      margin-right: 12px;
    }
    > span {
      font-weight: 600;
      font-size: 18px;
    }
  }
}
@media (max-width: 525px) {
  /*0~320*/
  .tracking-query-page {
    .content-wrapper {
      display: flex;
      height: 100%;
      padding: 10px 22px;
      flex-flow: column-reverse;
      .track-list {
        flex: 100%;
        :deep(.ant-collapse-item) {
          background-color: #fff;
          margin-bottom: 12px;
          border-radius: 4px;
          border-bottom: none;
        }
        :deep(.ant-collapse) {
          border: none;
          background-color: transparent;
        }
        :deep(.ant-collapse-content) {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border-color: rgba(240, 240, 240, 1);
        }
        .track-item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .track-item-header-content {
            font-size: 16px;
            font-weight: 700;
            color: #000;
            width: 100%;
            margin-bottom: 12px;
            .waybill-no {
              margin-bottom: 12px;
              .waybill-status {
                color: #fb4702;
                margin-left: 24px;
              }
            }
            .waybill-info {
              color: #818181;
              font-size: 14px;
              font-weight: 400;
              > span {
                display: inline-block;
                margin-right: 24px;
              }
              > span:nth-child(1) {
                width: 100%;
                margin-bottom: 12px;
              }
            }
          }
          .track-last-info {
            color: #2a2a2a;
            margin-right: 4rem;
            font-weight: 500;
          }
        }
        :deep(.ant-timeline-item) {
          color: #353535;
          font-weight: 500;
        }
      }
      .track-search {
        flex: 100%;
        margin-bottom: 12px;
      }
    }
  }
}
@media (min-width: 526px) and (max-width: 1199px) {
  /*0~320*/
  .tracking-query-page {
    .content-wrapper {
      display: flex;
      height: 100%;
      padding: 10px 22px;
      flex-flow: column-reverse;
      .track-list {
        flex: 100%;
        :deep(.ant-collapse-item) {
          background-color: #fff;
          margin-bottom: 12px;
          border-radius: 4px;
          border-bottom: none;
        }
        :deep(.ant-collapse) {
          border: none;
          background-color: transparent;
        }
        :deep(.ant-collapse-content) {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border-color: rgba(240, 240, 240, 1);
        }
        .track-item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .track-item-header-content {
            font-size: 16px;
            font-weight: 700;
            color: #000;
            .waybill-no {
              margin-bottom: 12px;
              .waybill-status {
                color: #fb4702;
                margin-left: 24px;
              }
            }
            .waybill-info {
              color: #818181;
              font-size: 14px;
              font-weight: 400;
              > span {
                margin-right: 24px;
              }
            }
          }
          .track-last-info {
            color: #2a2a2a;
            margin-right: 4rem;
            font-weight: 500;
          }
        }
        :deep(.ant-timeline-item) {
          color: #353535;
          font-weight: 500;
        }
      }
      .track-search {
        flex: 100%;
        margin-bottom: 12px;
      }
    }
  }
}
@media (min-width: 1200px) {
  /*769~+∞*/
  .tracking-query-page {
    .content-wrapper {
      display: flex;
      height: 100%;
      padding: 10px 22px;
      .track-list {
        flex: 1;
        margin-right: 12px;
        :deep(.ant-collapse-item) {
          background-color: #fff;
          margin-bottom: 12px;
          border-radius: 4px;
          border-bottom: none;
        }
        :deep(.ant-collapse) {
          border: none;
          background-color: transparent;
        }
        :deep(.ant-collapse-content) {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border-color: rgba(240, 240, 240, 1);
        }
        .track-item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .track-item-header-content {
            font-size: 16px;
            font-weight: 700;
            color: #000;
            .waybill-no {
              margin-bottom: 12px;
              .waybill-status {
                color: #fb4702;
                margin-left: 24px;
              }
            }
            .waybill-info {
              color: #818181;
              font-size: 14px;
              font-weight: 400;
              > span {
                margin-right: 24px;
              }
            }
          }
          .track-last-info {
            color: #2a2a2a;
            margin-right: 4rem;
            font-weight: 500;
          }
        }
        :deep(.ant-timeline-item) {
          color: #353535;
          font-weight: 500;
        }
      }
      .track-search {
        width: 360px;
      }
    }
  }
}
</style>
